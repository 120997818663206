
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ToggleItem',
  props: {
    id: String,
    label: String,
    on: Boolean,
  },
  emits: ['onToggle'],
  methods: {
    onClick() {
      this.$emit('onToggle')
    },
  },
})
