
import { defineComponent } from 'vue'

import ToggleItem from '@/components/ToggleItem.vue'

type ToggleInput = {
  id: string
  label: string
}

export default defineComponent({
  name: 'ToggleSet',
  components: { ToggleItem },
  props: {
    label: String,
    selection: {
      type: Object as () => Array<string>,
      required: true,
    },
    options: {
      type: Object as () => Array<ToggleInput>,
      required: true,
    },
  },
  emits: ['onChange'],
  methods: {
    onToggle(id: string) {
      const filtered = this.selection.filter((e) => e !== id)
      const newValue = filtered.length === this.selection.length ? [...filtered, id] : filtered
      this.$emit('onChange', newValue)
    },
  },
})
