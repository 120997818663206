
import { useStore } from '@/store'
import { defineComponent } from 'vue'
import { diceCoefficient as stringComparator } from 'string-comparison'
import ToggleSet from '@/components/ToggleSet.vue'
import { haveIntersection } from '@/util/util'

export default defineComponent({
  name: 'Search',
  components: { ToggleSet },
  data() {
    return {
      store: useStore(),
      criteria: useStore().state.searchCriteria,
      keywords: useStore().state.searchCriteria.keywords,
    }
  },
  computed: {
    searchResults() {
      return useStore().state.searchResults
    },
    hiddenSearchResultCount() {
      return useStore().state.hiddenSearchResultCount
    },
  },
  watch: {
    keywords() {
      this.criteria.keywords = this.keywords
      this.doSearch()
    },
  },
  methods: {
    onLightRequirementChange(values: string[]) {
      this.criteria.lightRequirement = values
      this.doSearch()
    },
    onDrainageChange(values: string[]) {
      this.criteria.drainage = values
      this.doSearch()
    },
    onGrowthHabitChange(values: string[]) {
      this.criteria.growthHabit = values
      this.doSearch()
    },
    onBloomTimeChange(values: string[]) {
      this.criteria.bloomTime = values
      this.doSearch()
    },
    doSearch() {
      this.store.commit('updateSearchCriteria', this.criteria)

      const {
        keywords: untrimmedKeywords,
        lightRequirement,
        drainage,
        growthHabit,
        bloomTime,
      } = this.criteria
      const keywords = untrimmedKeywords.trim()

      if (
        !keywords &&
        !lightRequirement.length &&
        !drainage.length &&
        !growthHabit &&
        !bloomTime.length
      ) {
        this.store.commit('updateSearchResults', [])
        return
      }

      const splitKeywords = keywords.split(/\s+/)
      const selected = this.store.state.plantDatabase.plants
        .flatMap((plant) => {
          if (
            lightRequirement.length &&
            !haveIntersection(lightRequirement, plant.lightRequirement)
          ) {
            return []
          }
          if (drainage.length && !haveIntersection(drainage, plant.moisture)) {
            return []
          }
          if (growthHabit.length && !growthHabit.includes(plant.growthHabit)) {
            return []
          }
          // if (bloomTime.length && !haveIntersection(drainage, plant.moisture)) {
          //   return []
          // }

          let ranking = 0
          if (keywords) {
            const commonNames = plant.commonNames.flatMap((name) => name.split(/[\s-]+/))
            const names = [plant.genus, plant.species, ...commonNames]

            // Find the best similarity score for each entered keyword, and then average them.
            ranking =
              splitKeywords.reduce((rankSum, keyword) => {
                return (
                  rankSum +
                  names.reduce((maxRank, name) => {
                    return Math.max(maxRank, stringComparator.similarity(keyword, name))
                  }, 0)
                )
              }, 0) / keywords.length
          }

          return [{ plant, ranking }]
        })
        .sort((a, b) => {
          if (keywords) {
            return b.ranking - a.ranking
          }
          return a.plant.commonNames[0].localeCompare(b.plant.commonNames[0], undefined, {
            sensitivity: 'base',
          })
        })
      this.store.commit('updateHiddenSearchResultCount', Math.max(0, selected.length - 10))
      this.store.commit('updateSearchResults', selected.slice(0, 10))
    },
  },
})
